import { type LoaderFunctionArgs, json } from '@remix-run/node'
import { Outlet, useLoaderData, useLocation } from '@remix-run/react'
import { parse as parseCookie } from 'cookie'
import { motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { isPrefetch } from 'remix-utils/is-prefetch'
import { promiseHash } from 'remix-utils/promise'
import AnnouncementBanner from '#app/components/announcement-banner'
import { Footer } from '#app/components/navigation/footer'
import Navbar from '#app/components/navigation/navbar'
import { WhyShopHere } from '#app/components/why-shop-here'
import useAuthenticatedUser from '#app/hooks/use-authenticated-user'
import useMediaQuery from '#app/hooks/useMediaQuery'
import { makeTimings } from '#app/server/utils/timing.server'
import { isPreview } from '#app/utils/isPreview'
import { languageSession } from '#app/utils/localisation/lang.server'
import {
	getLanguageFromCookieOrBrowserOrFallback,
	isAcceptedLanguage,
} from '#app/utils/localisation/lokalisation-utilities'
import { fetchStories } from '#app/utils/server/storyblok-server-fetch'
import {
	type AnnoncementStoryblok,
	type NavigationCategoriesStoryblok,
} from '#types/component-types-sb'

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('mainLayout loader')

	let headers = new Headers()
	if (isPrefetch(request)) {
		headers.set('Cache-Control', 'private, max-age=10, smax-age=0')
	} else {
		headers.set('Cache-Control', 'private, max-age=3600, smax-age=0')
	}
	const langSession = await languageSession.getSession(
		request.headers.get('cookie'),
	)

	const version = isPreview() ? 'draft' : 'published'

	// Set language to Danish by default, or get it from the cookie/browser
	let language = getLanguageFromCookieOrBrowserOrFallback(
		langSession.get('sessionLanguage'),
		request,
	)
	language = getLanguageFromCookieOrBrowserOrFallback(language, request)

	const cookieHeader = request.headers.get('Cookie')
	const cookies = parseCookie(cookieHeader || '')
	const bannerVisible = cookies.bannerVisible !== 'false'

	const url = new URL(request.url)
	const pathSegments = url.pathname.split('/')

	// Extract category key from the URL
	let categoryKey = 'kids' // Default value
	const possibleCategories = ['kids', 'women', 'men']
	for (const segment of pathSegments) {
		const cleanSegment = segment.split('-')[0].toLowerCase()
		if (possibleCategories.includes(cleanSegment)) {
			categoryKey = cleanSegment
			break
		}
	}

	const { announcementData, navigationStoryblok, searchData } =
		await promiseHash({
			announcementData: fetchStories({
				starts_with: 'announcement-banner',
				language: isAcceptedLanguage(language) ? language : 'da',
				version: version,
			}),
			navigationStoryblok: fetchStories({
				starts_with: 'navigation',
				language: isAcceptedLanguage(language) ? language : 'da',
				version: version,
			}),
			searchData: fetchStories({
				starts_with: 'search',
				language: isAcceptedLanguage(language) ? language : 'da',
				version: version,
			}),
		})

	// Prepare navigation data
	const navigation: NavigationCategoriesStoryblok[] =
		navigationStoryblok.data.stories.map((story: any) => story.content)

	const announcementContent =
		announcementData.data.stories.length > 0
			? announcementData.data.stories[0].content
			: null

	headers.set('Server-Timing', timings.toString())
	return json(
		{
			searchData,
			navigation,
			language, // This should now be Danish by default
			announcement: announcementContent,
			bannerVisible,
			categoryKey,
		},
		{
			headers: headers,
		},
	)
}

export default function MainLayout() {
	const { navigation, language, bannerVisible, announcement } =
		useLoaderData<typeof loader>()
	const isDesktop = useMediaQuery({ mobile: '820px' })
	const nodeRef = useRef<HTMLDivElement | null>(null)
	const location = useLocation()

	const userId = useAuthenticatedUser()

	useEffect(() => {
		const handlePopState = () => {
			if (nodeRef.current && !isDesktop) {
				const currentPath = window.location.pathname
				if (currentPath.startsWith('/product/')) {
					nodeRef.current.style.setProperty('display', 'block', 'important')
				} else {
					nodeRef.current.style.setProperty('display', 'none', 'important')
				}
			}
		}

		if (!isDesktop) {
			window.onpopstate = handlePopState
		} else {
			window.onpopstate = () => {}
		}

		return () => {
			window.onpopstate = null
		}
	}, [isDesktop])

	useEffect(() => {
		nodeRef.current &&
			nodeRef.current.style.setProperty('display', 'block', 'important')
	}, [location.pathname])

	return (
		<div>
			<motion.div className="sticky top-0 z-50" id="top">
				<AnnouncementBanner
					announcement={announcement as AnnoncementStoryblok}
					bannerVisible={bannerVisible}
				/>
				<Navbar
					userId={userId}
					navigationData={navigation}
					sessionLanguage={language}
				/>
			</motion.div>
			<div id="bag-item-portal" />
			<div ref={nodeRef}>
				<Outlet />

				<div>
					<WhyShopHere />
					<Footer />
				</div>
			</div>
		</div>
	)
}
